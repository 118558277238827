// NATIVE
import React, { useState } from 'react';



// INSTALLED
import { Route, Routes } from 'react-router-dom';



// CUSTOM
import { DashBoard } from '../pages/DashBoard';
import { ConfigSound } from '../pages/ConfigSound';
import { AudioPLayer } from '../pages/AudioPLayer';


export const PrivateRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/configurar-sonido" element={<ConfigSound />} />
        <Route path="/reproductor" element={<AudioPLayer />} />
      </Routes>
    </>
  )
}
