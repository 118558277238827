// NATIVE
import React, { useState, useEffect } from 'react';


// INSTALLED
import Grid from '@mui/material/Grid'; 
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardActions } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Clock from 'react-live-clock';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';


// CUSTOM
import { theme } from '../commons/theme';
import { LogoPlatform, LogoSpa, Header } from '../components';
import { useSelector } from 'react-redux';
import { allFirstLetterUpper, getFirstLetterUper, getLocalDate, getStorageEnviroments, getUrlEnviroments, getUrlImageHotel } from '../commons/utils';
import { IEnviroments } from '../commons/interfaces';
import { useNavigate } from "react-router-dom";




export const DashBoard = () => {

  const navigation = useNavigate();


  // REDUX
  const { spa, auth }:any  = useSelector( (state:any) => state );


  const [spaname, setSpaname] = useState('');
  const [user_name, setUserName] = useState('');
  const [spa_imagen, setSpaImagen] = useState('');
  const [enviroments, setEnviroments] = useState([]);
  const [loadedimghotel, setLoadedgImgHotel] = useState(false);
  const [open, setOpen] = React.useState(false);


  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    setSpaname(spa.spa_name);
  }, []);

  useEffect(() => {
    
    setSpaImagen(spa.spa_src_image_spa);
  }, []);


  useEffect(() => {
    setUserName(auth.displayName);
  }, []);

  useEffect(() => {
    showEnviroments();
  }, []);

  const showEnviroments = async() => {
      const enviroments = await getStorageEnviroments();
      setEnviroments(enviroments);
  }
  
  const goConfigAudio = (data:any, image:string, name:string) => {
   console.log(data)
    if ( data.length <= 0 ) {
         handleClickOpen();
         return;
    }
    navigation('/configurar-sonido', {state:{audios: data, image_enviroment: image, name_enviroment: name}});
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main> 
      <Grid container >

        <LogoPlatform />
        
        <Grid item md={5} sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4
          }}> 
          <Card sx={{ maxWidth: 345 }}>
          
            <CardActionArea>
             

              {
                !loadedimghotel && (
                  <Skeleton variant="rectangular" width={'100%'} height={150} />
                )
                
              }
              <img
                src={`${getUrlImageHotel()+'/'+spa_imagen}`}
                srcSet={`${getUrlImageHotel()+'/'+spa_imagen}`}
                height="150"
                width="100%"
                style={loadedimghotel ? {} : { display: 'none' }}
                onLoad={ () => setLoadedgImgHotel(true) }
              />
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    {  getFirstLetterUper(user_name) }
                  </Avatar>
                }
                
                title={user_name}
                subheader={getLocalDate()}
              />
              <Divider variant="middle" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                   {  allFirstLetterUpper(spaname) }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  
                  <Clock 
                   style={{
                     fontSize: '1.5em'
                   }} 
                   format={'hh:mm:ss a'}  
                   ticking={true} 
                   timezone={'AMERICA/BOGOTA'} 
                  />
                  {/* This impressive paella is a perfect party dish and a fun meal to cook
                  together with your guests. Add 1 cup of frozen peas along with the mussels,
                  if you like. */}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>

            </CardActions>
          </Card>
        </Grid>
        <Grid item  md={7}> 
          <ImageList sx={{ width: '95%', height: 500 }}>
            <ImageListItem key="Subheader" cols={2}>
              <ListSubheader component="div">Selecione ambiente</ListSubheader>
            </ImageListItem>
            {enviroments.map((item:IEnviroments) => (
              <ImageListItem key={item.enviroment_src} style={{ cursor: 'pointer' }} onClick={ () => goConfigAudio(item.audios, item.enviroment_src, item.enviroment_title) }>
                <img
                  src={`${getUrlEnviroments()+'/'+item.enviroment_src}?w=248&fit=crop&auto=format`}
                  srcSet={`${getUrlEnviroments()+'/'+item.enviroment_src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={'item.enviroment_title'}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.enviroment_title}
                  subtitle={`@${item.enviroment_sub_title}`}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.enviroment_title}`}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
     </Grid>
     </main>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Aviso de "} {spaname}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Lo sentimos,  este ambiente todavia no tiene audios asociados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Entiendo
          </Button>
        </DialogActions>
      </Dialog>

     {/* Footer */}
     <LogoSpa />
     {/* <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <CopyRight />
      </Box> */}
      {/* End footer */}
  </ThemeProvider>
  )
}
