import { createSlice } from '@reduxjs/toolkit';

export const spaSlice = createSlice({
    name: 'spa',
    initialState: {
        spa_id: null, 
        spa_name: null, 
        spa_address: null, 
        spa_cel_phone: null, 
        spa_src_avatar_spa: null,
        spa_src_image_spa: null,
    },
    reducers: {
        storeSpa: ( state, { payload } ) => {
            /* eslint-disable-line */
            state.spa_id = payload.spa_id;
            state.spa_name = payload.spa_name; 
            state.spa_address = payload.address; 
            state.spa_cel_phone = payload.cel_phone; 
            state.spa_src_avatar_spa = payload.src_avatar_spa;
            state.spa_src_image_spa = payload.src_image_spa
        },
        updateStoreSpa: ( state, { payload } ) => {
            /* eslint-disable-line */
            state.spa_id = payload.spa_id; 
            state.spa_name = payload.spa_name; 
            state.spa_address = payload.address; 
            state.spa_cel_phone = payload.cel_phone; 
            state.spa_src_avatar_spa = payload.src_avatar_spa;
            state.spa_src_image_spa = payload.src_image_spa
        }
        
    }
});


// Action creators are generated for each case reducer function
export const { storeSpa, updateStoreSpa } = spaSlice.actions;