

export const types = {
    contactos: {
       EMAIL:     'mailto: andres230687@hotmail.com',
       WHATSAPP: 'whatsapp://send?text=Hola&phone=+573155381242'

    },
    storage: {
        userStorage: '@QJ45w9vZ_storage_User',
        tokenStorage: '@UW75a2bA_storage_Token',
        spaStorage: '@WhI7Sis_storage_Spa',
        enviromentsStorage: '@PuYT87Hs_storage_Audios',
    }
} 