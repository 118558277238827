// NATIVE
import React, { useEffect, useState } from 'react';


// INSTALLED
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';


// CUSTOM
import { storeSpa } from '../store/spa';
import { login, startLogOut } from '../store/auth';
import { PublicRoutes } from './PublicRoutes';
import { Preload } from '../components/Preload';
import { PrivateRoutes } from './PrivateRoutes';
import { ProtectedRoute } from './ProtectedRoute';
import { IAuth, ILoading } from '../commons/interfaces';
import { useCheckToken } from '../hooks/useCheckToken';
import { useCheckSpas } from '../hooks';





export const Navigator = () => {


  const dispatch = useDispatch();

  // REDUX
  const initialState   = useSelector( ( state:IAuth) => state.auth );
  const { status }:any  = useSelector( (state:ILoading) => state.loading );

  const [isLogged,   setIsLogged] = useState(false)
  const [ initCheckToken ] = useCheckToken();
  const [ initCheckSpa ]   = useCheckSpas();

  useEffect(() => {
    checkToken();
  }, [initialState]);


  const checkToken = async () => {

    const user:any = await initCheckToken();
    
   if(user.token) {
      setIsLogged(true);
      await dispatch( login( user ));
      await checkSpa();
    }else{
      setIsLogged(false);
    } 
    
  }


  const checkSpa = async () => {

    const spa:any = await initCheckSpa();
    
    //SI O HAY DATOS O NO EXISTE EL KEY SPA EN EL STORAGE CIERRA SESSION
    if  ( Object.keys(spa).length <= 0) {
        await dispatch( startLogOut() );
        return;
    }

    const spa_storage = {
      spa_id: spa.spa_id, 
      spa_name: spa.spa_name, 
      spa_address: spa.spa_address, 
      cel_phone: spa.spa_cel_phone, 
      src_avatar_spa: spa.spa_src_avatar_spa,
      src_image_spa: spa.spa_src_image_spa
    }

    await dispatch( storeSpa(spa_storage) );

  }




  return (
    <>

      {
        status  === 'checking'  && <Preload/>
      }

      <BrowserRouter> 
        <Routes>
          {
            (isLogged)
            ? 
            <Route element={<ProtectedRoute user={isLogged} />}>
                <Route path="/*" element={<PrivateRoutes />} />
            </Route>
            :
            <Route path="/*" element={<PublicRoutes/>}/>
          }
        </Routes>
      </BrowserRouter>
    </>
  )
}
