// NATIVE
import React, { useState, useEffect } from 'react';

// INSTALLED
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; 
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, CardHeader, Skeleton } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeProvider } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';

// CUSTOM
import { CopyRight, Header, LogoPlatform, LogoSpa } from '../components';
import { theme } from '../commons/theme';
import { allFirstLetterUpper, getFirstLetterUper, getLocalDate, getUrlAudio, getUrlEnviroments, getUrlImageHotel } from '../commons/utils';
import { useSelector } from 'react-redux';


export const ConfigSound = ({props}:any) => {

  const { state } = useLocation();

  const navigation = useNavigate();

  // REDUX
  const { spa, auth }:any  = useSelector( (state:any) => state );

  const [audios, setAudios] = useState([]);
  const [image, setImage] = useState(false);
  const [loadedimghotel, setLoadedgImgHotel] = useState(false);
  const [user_name, setUserName] = useState('');
  const [enviroment_name, setEnviromentName] = useState('');

  const [audio_name, setAudioName] = useState('Pendiente');
  const [is_audio_name, setIsAudioName] = useState(false);
  
  const [timed, setTimed] = useState('Pendiente');
  const [is_timed, setIsTimed] = useState(false);


  const [btn_disabled, setBtnDisabled] = useState(true);

  const [data, setData] = useState({});
  const [tiempo, setTiempo] = useState('');

  useEffect(() => {
     setAudios(state.audios);
  }, [state.audios]);
  

  useEffect(() => {
    setImage(state.image_enviroment);
 }, [state.image_enviroment]);

 useEffect(() => {
  setUserName(auth.displayName);
}, []);

useEffect(() => {
  setEnviromentName(state.name_enviroment);
}, []);


// SI SELECIONA AUDIO VERIFICA ESTADO PARA ACTIVAR BOTON
useEffect(() => {
  ( is_audio_name && is_timed ) ? setBtnDisabled(false) : setBtnDisabled(true)
}, [is_audio_name]);


// SI SELECIONA TIEMPO VERIFICA ESTADO PARA ACTIVAR BOTON
useEffect(() => {
  ( is_audio_name && is_timed ) ? setBtnDisabled(false) : setBtnDisabled(true)
}, [is_timed]);


const setAudiosData = async(audio:any) => {
  await setAudioName(audio.audio_name);
  await setIsAudioName(true);
  setData(audio);
}


const setAudioTime = async(time:any) => {

  const tiempo =   time+' Minutos';
  await setTimed(tiempo);
  await setIsTimed(true);
  setTiempo(time)
}


const sendAudio = () => {
   
   const obj = {
     audio: data,
     tiempo: tiempo
   }
   console.log(obj)
   navigation('/reproductor', {state:{obj: obj }})
}



  return (
    <ThemeProvider theme={theme}> 
      <CssBaseline />
      <Header />
      <main> 
        <Button color="secondary" 
                type='button'
                style={{ position: 'absolute', top: 100, left: 20 }} 
                variant="contained" 
                startIcon={<ArrowBackIcon />}
                onClick={ () => navigation('/') }
                >
          Atras
        </Button>    
        <Grid container >
           
          <LogoPlatform />
          
          <Grid item md={7} sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4
          }}> 
          
          <ImageList sx={{ width: '95%', height: 500 }}>
            <ImageListItem key="Subheader" cols={2} >
              <ListSubheader component="div">Selecione Sonido</ListSubheader>
            </ImageListItem>
            {audios.map((audio:any) => (
              <ImageListItem key={audio.audio_id} style={{ cursor: 'pointer' }} onClick={ (event) => setAudiosData(audio) }>
                <img
                  src={`${getUrlAudio()+'/'+audio.audio_image_src}?w=248&fit=crop&auto=format`}
                  srcSet={`${getUrlAudio()+'/'+audio.audio_image_src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={audio.audio_name}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={audio.audio_name}
                  subtitle={`@${audio.audio_name}`}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${audio.audio_name}`}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>


        <Grid item md={5} sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4
          }}> 
          <Card sx={{ maxWidth: 345 }}>

            {
              !loadedimghotel && (
                <Skeleton variant="rectangular" width={'100%'} height={150} />
              )
              
            }

            <img
              src={`${getUrlEnviroments()+'/'+image}`}
              srcSet={`${getUrlEnviroments()+'/'+image}`}
              height="150"
              width="100%"
              style={loadedimghotel ? {} : { display: 'none' }}
              onLoad={ () => setLoadedgImgHotel(true) }
            />
            <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    {  getFirstLetterUper(user_name) } 
                  </Avatar>
                }
                
                title={user_name}
                subheader={getLocalDate()}
              />
            
            <CardActionArea>
              <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                 {'Ambiente:'} {  allFirstLetterUpper(enviroment_name) }
              </Typography>
              <Divider style={{ marginBottom: 10 }} variant="fullWidth" />
              
              <Typography gutterBottom variant="body1" component="div">
                 {'Audio:'} {  allFirstLetterUpper(audio_name) }  { is_audio_name ? <CheckCircleIcon  color='success'/> : <CancelIcon  color='error'/> } 
              </Typography>
              <Typography gutterBottom variant="body1" component="div">
                 {'Tiempo:'} {  allFirstLetterUpper(timed) } { is_timed ? <CheckCircleIcon  color='success'/> : <CancelIcon  color='error'/> } 
              </Typography>

              <FormControl color='secondary'>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginBottom: 10 }}>Selecione minutos</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    color='error'
                  >
                    <FormControlLabel
                      value="15"
                      control={<Radio color='secondary'/>}
                      label="15"
                      onClick={ () => setAudioTime(15) }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="20"
                      control={<Radio color='secondary'/>}
                      label="20"
                      onClick={ () => setAudioTime(20) }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="30"
                      control={<Radio color='secondary'/>}
                      label="30"
                      onClick={ () => setAudioTime(30) }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="50"
                      control={<Radio color='secondary'/>}
                      label="50"
                      onClick={ () => setAudioTime(50) }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      value="60"
                      control={<Radio color='secondary'/>}
                      label="60"
                      onClick={ () => setAudioTime(60) }
                      labelPlacement="bottom"
                    />
                  </RadioGroup>
              </FormControl>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
                disabled={btn_disabled}
                onClick={ sendAudio }
              >
                Siguiente
              </Button>
            </CardActions>
          </Card>
        </Grid>

        </Grid>
      </main>
      <LogoSpa />
    </ThemeProvider>
  )
}
