// NATIVE
import React, { useEffect, useState } from 'react';

// INSTALLED
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; 
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeProvider } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';



// CUSTOM
import { CopyRight, Header, LogoPlatform, LogoSpa } from '../components';
import { theme } from '../commons/theme';
import { allFirstLetterUpper, getUrlAudio, getUrlEnviroments, getUrlSrcAudio } from '../commons/utils';





export const AudioPLayer = () => {

  const { state } = useLocation();
  const navigation = useNavigate();

  const [audio_name, setAudioName] = useState('');
  const [audio_image, setImage] = useState('');
  const [audio_src, setAudioSrc] = useState('');

  const { audio, tiempo } = state.obj;



  useEffect(() => {
    setAudioName(audio.audio_name);
  }, [state]);
  
  useEffect(() => {
    setImage(audio.audio_image_src);
  }, [state]);
  
  useEffect(() => {
    setAudioSrc(audio.audio_src);
  }, [state]);
  
    const playlist = [
        { src: 'https://playurbanomp3.com/Canciones/Vico%20C/Vico%20C%20-%20Donde%20Comienzan%20La%20Guerra%20(WwW.PlayUrbano.Com).mp3' },
        { src: 'https://playurbanomp3.com/Canciones/Vico%20C/Vico%20C%20-%20Explosion%20(Live)%20(WwW.PlayUrbano.Com).mp3' },
        { src: 'https://playurbanomp3.com/Canciones/Vico%20C/Vico%20C%20-%20Dandote%20Vida%20(WwW.PlayUrbano.Com).mp3' },
    ];

    
    const [currentTrack, setTrackIndex] = React.useState(0)
    const handleClickNext = () => {
        setTrackIndex((currentTrack) =>
            currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        );
    };
    
    const handleEnd = () => {
      setTrackIndex((currentTrack) =>
              currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
          );
    }

  return (


    <ThemeProvider theme={theme}> 
      <CssBaseline />
      <Header />
      <main> 
        <Button color="secondary" 
                type='button'
                style={{ position: 'absolute', top: 100, left: 20 }} 
                variant="contained" 
                startIcon={<ArrowBackIcon />}
                onClick={ () => navigation(-1) }
                >
          Atras
        </Button> 
        <Grid container >
          <LogoPlatform />
          <Container maxWidth="md">
            <Box sx={{ height: '100vh' }}> 
            <Card >
              <CardMedia
                sx={{ height: 350 }}
                image={`${getUrlAudio()+'/'+audio_image}`}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {  allFirstLetterUpper(audio_name) }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over 6,000
                  species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
              <CardActions>
                <AudioPlayer
                  // src={playlist[currentTrack].src}
                  src={`${getUrlSrcAudio()+'/'+audio_src}`}  
                  // showSkipControls
                  autoPlayAfterSrcChange={false}
                  onClickNext={handleClickNext}
                  onEnded={handleEnd}
                />
              </CardActions>
            </Card>
            </Box>
          </Container>
          
          
      </Grid>
      </main>
      <LogoSpa />
      </ThemeProvider>
    
  )
}
