
import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth'
// import { errorsSlice } from './errors'
import { loadingSlice } from './loading'
import { spaSlice } from './spa'
// import { networkConnectSlice } from './networkConnect'
// import { neighborHoodsauthSlice } from './neighborhoods'
// import { typePqrsSlice } from './pqrs'
// import { suscriptionsSlice } from './suscriptions'
// import { profileSlice } from './profile'
// import { companiesSlice } from './companies'
// import { globalsSlice } from './globals'

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    spa: spaSlice.reducer,
    // errors: errorsSlice.reducer,
    loading: loadingSlice.reducer,
    // network: networkConnectSlice.reducer,
    // neighborhoods: neighborHoodsauthSlice.reducer,
    // typepqrs: typePqrsSlice.reducer,
    // suscriptions: suscriptionsSlice.reducer,
    // profileNeighborhood: profileSlice.reducer,
    // companiesStore: companiesSlice.reducer,
    // globals: globalsSlice.reducer
  }
})