
// NATIVE 

// INSTALLED 
import { Dispatch } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

// CUSTOM 
import { hasErrorRequest } from '../errors';
import { types } from '../../commons/types/types';
import { loginWithEmailPassword } from '../../services';
import { logout, login } from './';
import { checkingPreload, unCheckingPreload } from '../loading';
import { ILoginWithEmailPassword, IStorageAudios } from '../../commons/interfaces';
import { storeSpa } from '../spa';
import { IStorageEnviroments } from '../../commons/interfaces/enviroments.interface';



export const startLoginWithEmailPassword = ({ email, password }:ILoginWithEmailPassword):any => {

    try {

        return async( dispatch: any  ) => {

       
            dispatch( checkingPreload() );
    
            const result = await  loginWithEmailPassword({ email, password });

            console.log(result)
            dispatch( unCheckingPreload() );
    
            if ( !result ) {
                // console.log('wwwwwwwwwwepaaaaaaaaaaaaaa')
                dispatch( hasErrorRequest(true) );
                return false;
            }
    
            if ( !result.ok ) return result;
           
            const {  user, spa, enviroments } = result.data;
            
            const { token } = result;

            const user_storage = {
                displayName: user.name,
                photoURL: user.src_avatar ?? '',
                email: user.email,
                token: token,
                errorMessage: null
            };

            const spa_storage = {
                spa_id: spa.spa_id, 
                spa_name: spa.spa_name, 
                spa_address: spa.address, 
                spa_cel_phone: spa.cel_phone, 
                spa_src_avatar_spa: spa.src_avatar_spa,
                spa_src_image_spa: spa.src_image_spa
            }

            const save_enviroments = enviroments.map( (enviroment: IStorageEnviroments) => (
                {
                    enviroment_id: enviroment.enviroment_id,  
                    enviroment_title: enviroment.enviroment_title,  
                    enviroment_sub_title: enviroment.enviroment_sub_title,
                    enviroment_description: enviroment.enviroment_description,
                    enviroment_src: enviroment.enviroment_src,
                    fk_spa_id: enviroment.fk_spa_id,
                    audios:  enviroment.audios
                }
            ));
            

          
            
            await AsyncStorage.setItem(types.storage.userStorage, JSON.stringify(user_storage));
            await AsyncStorage.setItem(types.storage.spaStorage, JSON.stringify(spa_storage));
            await AsyncStorage.setItem(types.storage.enviromentsStorage, JSON.stringify(save_enviroments));
            
            await dispatch( storeSpa(spa) )
            await dispatch( login( user_storage ));
            
        }
        
    } catch (error) {
        console.log('conooooooooooooooooooooooooooooooooooo ', error)
    }
}


export const startLogOut = (): any => {


    try {

        return async( dispatch: any  ) => {

            dispatch( checkingPreload() );
                await AsyncStorage.removeItem(types.storage.userStorage);
                await AsyncStorage.removeItem(types.storage.spaStorage);
                await AsyncStorage.removeItem(types.storage.enviromentsStorage);
                dispatch( logout() );
            dispatch( unCheckingPreload() );
    
        }
        
    } catch (error) {
        console.log('conooooooooooooooooooooooooooooooooooo ', error);
    }

}



