// NATIVE
import React, { useState } from 'react';


// INSTALLED
import { Route, Routes } from 'react-router-dom';


// CUSTOM
import { LoginPage } from '../pages/LoginPage';

export const PublicRoutes = () => {

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<LoginPage />} />
    </Routes>
  )
}
